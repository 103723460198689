export const farm_server = [
    {
        path: "/farm_work",
        name: "farm_work",
        meta: { title: "农机作业服务" },
        component: () => import("@/views/BackSysFile/traceability_system/farm_server/farm_work/FarmWork.vue"),
    },
    {
        path: "/recycle_record",
        name: "recycle_record",
        meta: { title: "田间秸秆回收记录" },
        component: () => import("@/views/BackSysFile/traceability_system/farm_server/recycle_record/RecycleRecord.vue"),
    }
]