export const farm_management = [
    {
        path: "/farm_purchase",
        name: "farm_purchase",
        meta: { title: "农资采购" },
        component: () => import("@/views/BackSysFile/traceability_system/farm_management/farm_purchase/FarmPurchase.vue"),
    },
    {
        path: "/farm_pull",
        name: "farm_pull",
        meta: { title: "农资出库" },
        component: () => import("@/views/BackSysFile/traceability_system/farm_management/farm_pull/FarmPull.vue"),
    }
]