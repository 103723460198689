export const base_management = [
    {
        path: "/base-info",
        name: "base-info",
        meta: { title: "基地信息" },
        component: () => import("@/views/BackSysFile/back_stage_management/base_management/BaseInfo/BaseInfo.vue"),
    }, {
        path: "/dow-plot",
        name: "dow-plot",
        meta: { title: "地块绘制" },
        component: () => import("@/views/BackSysFile/back_stage_management/base_management/DowPlot/DowPlot.vue"),
    },
    {
        path: "/staff-info",
        name: "staff-info",
        meta: { title: "员工信息" },
        component: () => import("@/views/BackSysFile/back_stage_management/base_management/StaffInfo/StaffInfo.vue"),
    },
]