import { crop_variety } from './crop_variety/index'
import { production_management } from './production_management/index'
import { warehouse_management } from './warehouse_management/index'
import { sell_management } from './sell/index'
import { farm_management } from './farm_management'
import { quality_control } from './quality_control'
import { tracea_file } from './tracea_file'
import { enterprise_management } from './enterprise_management'
import { group_management } from './group_management'
import { farm_server } from './farm_server'

export const tracea_system = [
    ...crop_variety,
    ...production_management,
    ...farm_server,
    ...warehouse_management,
    ...sell_management,
    ...farm_management,
    ...quality_control,
    ...tracea_file,
    ...enterprise_management,
    ...group_management
] 