export const farmland_basic_management = [
    {
        path: "/plot_model",
        name: "plot_model",
        meta: { title: "地块数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/plot_model/FarmlandPlotModel.vue"),
    },
    {
        path: "/type_model",
        name: "type_model",
        meta: { title: "作物数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/type_model/FarmladTypeModel.vue"),
    },
    {
        path: "/output_model",
        name: "output_model",
        meta: { title: "产量数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/output_model/FarmlandOutputModel.vue"),
    },
    {
        path: "/irrigate_model",
        name: "irrigate_model",
        meta: { title: "灌溉数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/irrigate_model/FarmlandIrrgateModel.vue"),
    },
    {
        path: "/weather_model",
        name: "weather_model",
        meta: { title: "天气数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/weather_model/FarmladWeatherModel.vue"),
    },
    {
        path: "/soli_model",
        name: "soli_model",
        meta: { title: "墒情数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/soli_model/FarmladSoliModel.vue"),
    },
    {
        path: "/pest_model",
        name: "pest_model",
        meta: { title: "病虫情数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/pest_model/FarmlandPestModel.vue"),
    },
    {
        path: "/surveillance_camera_model",
        name: "surveillance_camera_model",
        meta: { title: "监控数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/surveillance_camera_model/FarmlandSurveillanceCameraModel.vue"),
    },
    {
        path: "/water_and_fertilizer_model",
        name: "water_and_fertilizer_model",
        meta: { title: "水肥一体数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/water_and_fertilizer_model/FarmlandWaterAndFertilizerModel.vue"),
    },
    {
        path: "/disaster_model",
        name: "disaster_model",
        meta: { title: "灾害预警信息发布模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/disaster_model/FarmladDiasster.vue"),
    },
    {
        path: "/seedling_condition_model",
        name: "seedling_condition_model",
        meta: { title: "苗情数据分析模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/seedling_condition_model/FarmlandSeedlingCondition.vue"),
    },
    {
        path: "/after_calamity_model",
        name: "after_calamity_model",
        meta: { title: "灾后重建应急指导模型" },
        component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/after-calamity_model/FarmlandAfterCalamityModel.vue"),
    },

]