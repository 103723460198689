export const production_management = [
    {
        path: "/plant",
        name: "plant",
        meta: { title: "种植管理" },
        component: () => import("@/views/BackSysFile/traceability_system/production_management/plant/Plant.vue"),
    },
    {
        path: "/farm",
        name: "farm",
        meta: { title: "农事管理" },
        component: () => import("@/views/BackSysFile/traceability_system/production_management/farm/Farm.vue"),
    },
    {
        path: "/harvest",
        name: "harvest",
        meta: { title: "采收管理" },
        component: () => import("@/views/BackSysFile/traceability_system/production_management/harvest/Harvest.vue"),
    },
    {
        path: "/processing",
        name: "processing",
        meta: { title: "加工管理" },
        component: () => import("@/views/BackSysFile/traceability_system/production_management/processing/Processing.vue"),
    }
]