export const water_and_fertilizer_management = [
    {
        path: '/irrigation_management',
        name: 'irrigation_management',
        meta: {
            title: '灌水管理',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/water_and_fertilizer_management/irrigation_management/IrrigationManagement.vue')
    },
    {
        path: '/fertilizer_management',
        name: 'fertilizer_management',
        meta: {
            title: '施肥管理',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/water_and_fertilizer_management/fertilizer_management/FertilizerManagement.vue')
    },
]