export const tracea_file = [
    {
        path: "/tracea_record",
        name: "tracea_record",
        meta: { title: "溯源记录" },
        component: () => import("@/views/BackSysFile/traceability_system/tracea_file/tracea_record/TraceaRecord.vue"),
    }, {
        path: "/tracea_statistic",
        name: "tracea_statistic",
        meta: { title: "溯源统计" },
        component: () => import("@/views/BackSysFile/traceability_system/tracea_file/tracea_statistic/TraceaStatistic.vue"),
    }
]