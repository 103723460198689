export const alarm_management = [
    {
        path: "/device-type",
        name: "device-type",
        meta: { title: "设备离线告警" },
        component: () => import("@/views/BackSysFile/back_stage_management/alarm_management/deviceType/DeviceType.vue"),
    }, {
        path: "/watch-value",
        name: "watch-value",
        meta: { title: "监测数值告警" },
        component: () => import("@/views/BackSysFile/back_stage_management/alarm_management/watchValue/WatchValue.vue"),
    }
]