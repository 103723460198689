<template>
    <div class="login_container common_image">
        <!-- 文字图片盒子 -->
        <div class="font_box flex-row">
            <div class="font_box_content flex-col">
                <img src="@/assets/image/login/font1.png" alt="" />
                <img src="@/assets/image/login/font2.png" alt="" />
            </div>
        </div>

        <!-- 登录框 -->
        <div class="login_box flex-row">
            <div class="login_content common_image">
                <!-- 边框图片 -->
                <div class="login_box_border_box flex-row">
                    <img src="@/assets/image/login/login_box_border.png" />
                </div>

                <!-- 标题 -->
                <div class="login_title flex-row">
                    <img src="@/assets/image/login/left.png" alt="" />
                    <p>用户登录</p>
                    <img src="@/assets/image/login/right.png" alt="" />
                </div>

                <!-- 用户名 密码输入框 -->
                <div
                    class="login_input_box flex-row"
                    v-for="(item, index) in inputList"
                    :key="index"
                >
                    <div class="login_input_content flex-row">
                        <img :src="getFourceOrNotImage(item)" />
                        <p :style="{ color: getInputTitleColor(item) }">
                            {{ item.title }}
                        </p>
                        <input
                            v-model="item.value"
                            :maxlength="item.maxlength"
                            :type="item.type"
                            :placeholder="item.placeholder"
                            @focus="item.isFource = true"
                            @blur="item.isFource = false"
                        />
                    </div>
                </div>

                <!-- 登录按钮 -->
                <div class="login_btn_box flex-row">
                    <div @click="login">登录</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import jsencrypt from "jsencrypt";
import { BasicService } from "@/util/http/index";
import { LoginAndHome } from "@/api/login_home";
@Component({})
export default class Login extends Vue {
    private inputList = [
        {
            title: "账号",
            isFource: false,
            type: "text",
            placeholder: "请输入用户名",
            value: "",
            maxlength: 20,
        },

        {
            title: "密码",
            isFource: false,
            type: "password",
            placeholder: "请输入密码",
            value: "",
            maxlength: 20,
        },
    ];

    get getFourceOrNotImage() {
        return (item: any) => {
            if (item.title === "账号")
                return item.isFource
                    ? require("@/assets/image/login/user_icon_fource.png")
                    : require("@/assets/image/login/user_icon.png");
            else
                return item.isFource
                    ? require("@/assets/image/login/password_icon_fource.png")
                    : require("@/assets/image/login/password_icon.png");
        };
    }
    get getInputTitleColor() {
        return (item: any) => {
            return item.isFource ? "#fff" : "#C7D6FF";
        };
    }

    private handlerParams(): { username: string; password: string } {
        // 创建一个新的JSEncrypt对象
        const encrypt = new jsencrypt();
        // 设置公钥，公钥由后台服务器提供的
        encrypt.setPublicKey(
            "MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAMB8DbRxGfu1aZTdH2sQDpScf7UrOqhkC7Qkl0OKz7CBlOnwkqeQUEExrxOxojSa4DT8nR2xjbALqbd3mxPqXBd4uT0o7FqyO0zdjaUhIuSzpVEbqoT0JgoTKdsxN/ZaPKAjTynGOIIMtMLlFEnnd+iYT0kTSJcwXJjL2QqLUlzHAgMBAAECgYApVrsuxJea+nQpd2/282TeP9ohk54uagYlczJVXCuMsdfEgllMyW6jAcOjDCRU/eonFxW7OdW/OFIDyBkzJsMOG+UMBFVxJCgnz5N6cChyoNDpNq1J8AHeANJHdZbxOE9TFc9Gh2ujVbHRlFovBqFUmhb/jejeoH+ljsvkrlrFkQJBAOwF2rYSChHn0QG6V02JHXPGDKW7o6W8DAU7bU3nyIWRmWCnOZB0xmpYMlqGtcqC7lWKy8gke023XX8F1UIk8+kCQQDQxs+uNgL4OYL0/iMb4gtSy9wojRIQTft1i3rby0yHKG1pnipBHGxmaQTC3kXoKwdnPger7mzxnAJYM+NqeM0vAkBNcopXDkpRn9DckUnOfny0nbtOsui7HJ6RJPP0CWeqNKGwro7cEQV+pdw4ClYi0p3kYfwGe7oeQVojsUVORBPpAkBdfRmUtmUACKjm0kKggsl1SwqMMherq2/2A/6BG/lp8KWGDSh4vaSg+1gBdw0w2MvY5IME76BjVOwL2Th1/iBLAkAvWzdNs+5sTfoz3Yq4zxLlNNhiv6VWZ3yTLS50OUBEl1OV+VK+wSqvYSpN7WsDAe4o2VwDW2VJ1RdEagFwhYsM"
        );
        let username = "";
        let password = "";
        if (this.inputList[0].value)
            username = encrypt.encrypt(this.inputList[0].value) || "";
        if (this.inputList[1].value)
            password = encrypt.encrypt(this.inputList[1].value) || "";
        return { username, password };
    }

    //event
    private login() {
        if (this.inputList[1].value && this.inputList[1].value.length >= 6) {
            const { username, password } = this.handlerParams();
            if (username && password) {
                new BasicService().login(
                    this.handlerParams(),
                    ({ code, data: { id, name } }: any) => {
                        if (code === 200) {
                            // console.log(id, name);
                            this.$store.dispatch("setUserId", id);
                            this.$store.dispatch("setUserName", name);
                            localStorage.setItem("id", id);
                            new LoginAndHome().getMenus((res: any) => {
                                this.$store.dispatch("setMenuList", res.data);
                                this.$router.push({ path: res.data[0].url });
                            });
                        }
                    }
                );
            } else {
                this.$message.error("账号或密码不能为空");
            }
        } else {
            this.$message.error("密码长度不能小于6位");
        }
    }
}
</script>

<style scoped lang="less">
.login_container {
    width: 100vw;
    height: 100vh;
    background-image: url(@/assets/image/login/login_bg.png);
    .font_box {
        width: 100%;
        justify-content: center;
        > .font_box_content {
            width: calc(771 / 1920 * 100vw);
            align-items: center;
            margin-top: calc(194 / 1080 * 100vh);
            > img {
                width: 100%;
                height: calc(59 / 1920 * 100vw);
                flex-shrink: 0;
            }
            > img:nth-child(1) {
                margin-bottom: calc(36 / 1080 * 100vh);
            }
            > img:nth-child(2) {
                width: calc(551 / 1920 * 100vw);
            }
        }
    }
    .login_box {
        width: 100%;
        justify-content: center;
        .login_content {
            width: calc(672 / 1920 * 100vw);
            height: calc(464 / 1080 * 100vh);
            margin-top: calc(58 / 1080 * 100vh);
            background-image: url(@/assets/image/login/login_box_bg.png);
            // 边框图片 阴影
            .login_box_border_box {
                width: 100%;
                justify-content: center;
                position: relative;
                > img {
                    flex-shrink: 0;
                    width: calc(110 / 1920 * 100vw);
                    position: absolute;
                    top: calc(-10 / 1080 * 100vh);
                }
            }
            // 标题
            .login_title {
                width: 100%;
                height: calc(108 / 1080 * 100vh);
                justify-content: center;
                align-items: center;
                margin-top: calc(8 / 1080 * 100vh);
                > img {
                    width: calc(44 / 1920 * 100vw);
                    height: calc(24 / 1080 * 100vh);
                }
                > p {
                    font-family: PingFang SC;
                    font-weight: 600;
                    font-size: calc(36 / 1920 * 100vw);
                    color: #46bbfe;
                    margin: 0 calc(38 / 1920 * 100vw);
                }
            }

            // 输入框
            .login_input_box {
                width: 100%;
                margin-top: calc(30 / 1080 * 100vh);
                justify-content: center;
                .login_input_content {
                    width: calc(552 / 1920 * 100vw);
                    height: calc(56 / 1080 * 100vh);
                    flex-shrink: 0;
                    align-items: center;
                    background: rgba(4, 77, 144, 0.8);
                    border: 2px solid #00b4ff;
                    box-sizing: border-box;
                    margin-bottom: calc(40 / 1080 * 100vh);
                    > img {
                        width: calc(20 / 1920 * 100vw);
                        height: calc(20 / 1080 * 100vh);
                        margin-left: calc(15 / 1920 * 100vw);
                        margin-right: calc(12 / 1920 * 100vw);
                    }
                    > p {
                        width: calc((16 * 2 + 5) / 1920 * 100vw);
                        font-size: calc(16 / 1920 * 100vw);
                        margin-right: calc(12 / 1920 * 100vw);
                    }
                    > input {
                        width: calc(490 / 1920 * 100vh);
                        height: 100%;
                        color: #fff;
                        font-size: calc(16 / 1920 * 100vw);
                        background: transparent;
                    }
                }
                > div:last-child {
                    margin-bottom: 0;
                }
            }

            // 登录
            .login_btn_box {
                width: 100%;
                justify-content: center;
                margin-top: calc(60 / 1080 * 100vh);
                > div {
                    width: calc(552 / 1920 * 100vw);
                    height: calc(56 / 1080 * 100vh);
                    background: #007de9;
                    border-radius: 4px;
                    color: #fff;
                    font-size: calc(16 / 1920 * 100vw);
                    text-align: center;
                    line-height: calc(56 / 1080 * 100vh);
                    cursor: pointer;
                }
            }
        }
    }
}

input::-webkit-input-placeholder {
    // color: #fff; /* 把placeholder颜色改为fff */
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999999s;
    -webkit-transition: color 9999999s ease-out,
        background-color 9999999s ease-out;
}
</style>
