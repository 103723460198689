export const system_management = [
    {
        path: '/account-number-management',
        name: 'account-number-management',
        meta: {
            title: '账号管理',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/system_management/account_number_management/AccountNumberManagement.vue')
    },
    {
        path: '/role-management',
        name: 'role-management',
        meta: {
            title: '角色管理',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/system_management/role_management/RoleManagement.vue')
    },
    {
        path: '/edit-psd',
        name: 'edit-psd',
        meta: {
            title: '修改密码',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/system_management/edit_psd_management/EditPsdManagement.vue')
    },
    {
        path: '/menu-management',
        name: 'menu-management',
        meta: {
            title: '菜单管理',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/system_management/menu_management/MenuManagement.vue')
    },
    {
        path: '/department-management',
        name: 'department-management',
        meta: {
            title: '部门管理',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/system_management/department_management/DepartmentManagement.vue')
    }
]