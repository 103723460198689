export const video_system = [
    {
        path: '/rvc',
        name: 'rvc',
        meta: {
            title: '实时视频',
        },
        component: () => import('@/views/ScreenFile/video_system/rvc/Rvc.vue')
    },
    {
        path: '/dvr',
        name: 'dvr',
        meta: {
            title: '硬盘录像机',
        },
        component: () => import('@/views/ScreenFile/video_system/dvr/Dvr.vue')
    },
    {
        path: '/equipment-information',
        name: 'equipment-information',
        meta: {
            title: '设备信息',
        },
        component: () => import('@/views/ScreenFile/video_system/equipment_information/EquipmentInformation.vue')
    }
] 