export const device_management = [
    {
        path: "/device-list",
        name: "device-list",
        meta: { title: "设备列表" },
        component: () => import("@/views/BackSysFile/back_stage_management/device_management/device_list/DeviceList.vue"),
    }, {
        path: "/history-data",
        name: "history-data",
        meta: { title: "历史数据" },
        component: () => import("@/views/BackSysFile/back_stage_management/device_management/history_data/HistoryData.vue"),
    },
]