import { instance } from "@/util/http/header";
import axios, { AxiosResponse } from "axios";
import { Message, Loading } from "element-ui";
import { saveAs } from 'file-saver';
import Vue from "vue";

export class BasicService {
	protected instance = instance;
	protected loading = false;
	private vue: Vue = new Vue()

	public post(
		url: string,
		data: any,
		loading?: boolean,
		successMessage?: boolean,
		errorMessage?: boolean,
		onSuccess?: Function,
		onError?: Function
	) {
		const serviceLoading = Loading.service({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		})

		loading && serviceLoading;
		this.instance.post(url, data).then((response: any) => {

			serviceLoading.close()
			if (response) {
				const { data } = response;
				successMessage && Message.success(response.message);
				onSuccess && onSuccess(data);
			}
		}).catch((error) => {

			serviceLoading.close()
			errorMessage && Message.error(error);
			onError && onError(error);
		}).finally(() => {
			serviceLoading.close()
		})

	}

	public uploadFile(
		url: string,
		file: File,
		loading?: boolean,
		successMessage?: boolean,
		errorMessage?: boolean,
		onSuccess?: Function,
		onError?: Function
	) {
		const serviceLoading = Loading.service({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		})
		loading && serviceLoading;
		const formData = new FormData();
		formData.append("file", file);
		this.instance.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}).then((response: AxiosResponse) => {
			serviceLoading.close()
			successMessage && Message.success(response.data.message);
			onSuccess && onSuccess(response.data);
		}
		).catch((error) => {
			serviceLoading.close()
			errorMessage && Message.error(error);
			onError && onError(error);
		}).finally(() => {
			serviceLoading.close()
			loading && (this.loading = false);
		})
	}

	public get(
		url: string,
		params: any,
		loading: boolean,
		successMessage: boolean,
		errorMessage: boolean,
		onSuccess?: Function,
		onError?: Function
	) {
		const serviceLoading = Loading.service({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		})
		loading && serviceLoading;
		this.instance.get(url, { params }).then((response: any) => {
			if (response) {
				serviceLoading.close()
				const { data } = response;
				successMessage && Message.success(response.message);
				onSuccess && onSuccess(response);
			}
		}).catch((error) => {
			serviceLoading.close()
			errorMessage && Message.error(error);
			onError && onError();
		}).finally(() => {
			serviceLoading.close()
		})
	}


	public getFile(url: string, form?: any, callback?: Function) {
		axios.get(url, {
			params: form,
			responseType: 'blob'  // 表示服务器将返回二进制数据
		}).then(response => {
			// 创建一个 Blob 实例，表示从服务器接收到的数据
			console.log(response)
			const blob = new Blob([response.data], { type: 'application/octet-stream' });

			// 从 Content-Disposition 头获取文件名
			const contentDisposition = response.headers['content-disposition'];
			console.log(contentDisposition)
			let filename = 'file.xlsx';
			if (contentDisposition) {
				filename = contentDisposition.split('=')[1];
			}

			// 使用 file-saver 库保存文件
			saveAs(blob, filename);
		}).catch(error => {
			console.error('导出文件失败', error);
		});
	}

	public getFile2(url: string, form?: any, callback?: Function) {
		this.instance.get(url, {
			params: { ...form },
			responseType: 'blob'  // 表示服务器将返回二进制数据
		}).then(response => {
			console.log(response)
			// 创建一个 Blob 实例，表示从服务器接收到的数据 
			const blob = new Blob([response.data], { type: 'application/octet-stream' });

			// 从 Content-Disposition 头获取文件名
			const contentDisposition = response.headers['content-disposition'];
			console.log(contentDisposition)
			let filename = 'file.xlsx';
			if (contentDisposition) {
				filename = contentDisposition.split('=')[1];
			}

			// 使用 file-saver 库保存文件
			saveAs(blob, filename);
		}).catch(error => {
			console.error('导出文件失败', error);
		});
	}



	public login(
		data: any,
		onSuccess?: (data: any) => void,
		onError?: (err: Error) => void
	) {
		const serviceLoading = Loading.service({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		})
		serviceLoading;
		//创建表单数据格式
		const formData = new FormData();
		//解构data
		const { username, password } = data;
		//添加到表单数据中
		formData.append("username", username);
		formData.append("password", password);
		//设置请求头
		const headers = {
			"Content-Type": "multipart/form-data",
		};
		//发送请求
		this.instance
			.post("login", formData, { headers })
			.then((response: AxiosResponse) => {
				serviceLoading.close()
				onSuccess && onSuccess(response);
			})
			.catch((error) => {
				serviceLoading.close()
				onError && onError(error);
			}).finally(() => {
				serviceLoading.close()
			})

	}

	//创建loading
	// protected serviceLoading = Loading.service({
	// 	lock: true,
	// 	text: 'Loading',
	// 	spinner: 'el-icon-loading',
	// 	background: 'rgba(0, 0, 0, 0.7)'
	// })

	//关闭loading
	// protected closeLoading = () => {
	// 	this.vue.$nextTick(() => {
	// 		this.serviceLoading.close();
	// 	})
	// }

}
