import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/login/Login.vue'

/**
 * 后台管理系统模块
 */
import { back_stage_management } from '@/router/back_stage_management/index'

/**
 * 农田视频可视化系统
 */
import { video_system } from '@/router/video_system/index'

/**
 * 溯源管理
 */
import { tracea_system } from '@/router/tracea_system'

/**
 * 
 */
import { farmland_basic_management } from './farmland_basic_information_data_platform/index'
import { industry_fusion_sys } from './industry_fusion_sys'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录',
		},
		component: Login
	},
	// 新版本桐庐大屏首页
	{
		path: '/tonglu_home',
		name: 'tonglu_home',
		meta: {
			title: '首页',
		},
		children: [
			{
				path: '/agriculture_a_map',
				name: 'agriculture_a_map',
				meta: {
					title: '农业一张图',
				},
				component: () => import('@/views/bigscreen_page/agriculture_a_map/AgricultureMap.vue')
			},
			{
				path: '/env_test_service',
				name: 'env_test_service',
				meta: {
					title: '环境监测服务',
				},
				component: () => import('@/views/bigscreen_page/env_monitoring_service/EnvMonitoringService.vue')
			},
			{
				path: '/pest_detection',
				name: 'pest_detection',
				meta: {
					title: '病虫害监测',
				},
				component: () => import('@/views/bigscreen_page/pest_monitoring/PestMonitoring.vue')
			},
			{
				path: '/disaster_mon_warning',
				name: 'disaster_mon_warning',
				meta: {
					title: '灾害监测预警',
				},
				component: () => import('@/views/bigscreen_page/disaster_monitoring/DisasterMonitoring.vue')
			},


			// 农业一张图 同级目录下的 
			{
				path: "/land_profile",

				name: "land_profile",
				meta: {
					title: "土地概况"
				},
				component: () => import('@/views/bigscreen_page/agriculture_a_map/child_page/land_profile/LandProfile.vue')
			},
			{
				path: "/output_profile",
				name: "output_profile",
				meta: {
					title: "产量概况"
				},
				component: () => import('@/views/bigscreen_page/agriculture_a_map/child_page/output_profile/OutputProfile.vue')
			},
			{
				path: "/internet_of_things_overview",
				name: "internet_of_things_overview",
				meta: {
					title: "物联网概况"
				},
				component: () => import('@/views/bigscreen_page/agriculture_a_map/child_page/internet_of_things_overview/InternetOfThingsOverview.vue')
			},

			// 环境 同级目录下的子页面
			{
				path: "/env_device_info",
				name: "env_device_info",
				meta: {
					title: "设备详情"
				},
				component: () => import('@/views/bigscreen_page/env_monitoring_service/child_page/EnvChildInfo.vue')
			},

			// 病虫害 同级目录下的子页面 
			{
				path: "/pest_device_info",
				name: "pest_device_info",
				meta: {
					title: "设备详情"
				},
				component: () => import('@/views/bigscreen_page/pest_monitoring/child_page/PestChildInfo.vue')
			},
		],
		component: () => import('@/views/bigscreen_home/BigScreenHome.vue')
	},
	{
		path: '/back-stage-management-home',
		name: 'back-stage-management-home',
		meta: {
			title: '后台系统管理',
		},
		children: [
			...back_stage_management
		],
		component: () => import('@/views/home/Home.vue')
	},
	{
		path: '/video-system-home',
		name: 'video-system-home',
		meta: {
			title: '农田视频可视化系统',
		},
		children: [
			...video_system
		],
		component: () => import('@/views/ScreenFile/video_system/Home/VideoSystemHome.vue')
	},


	{
		path: '/farmland_foundation_management',
		name: 'farmland_foundation_management',
		meta: {
			title: '农田信息管理系统',
		},
		component: () => import('@/views/ScreenFile/farmland_foundation_management/FarmlandFoundationManagement.vue')
	},
	// {
	// 	path: '/land_profile',
	// 	name: 'land_profile',
	// 	meta: {
	// 		title: '土地概况',
	// 	},
	// 	component: () => import('@/views/ScreenFile/farmland_foundation_management/child_page/ChildLeftOne/LandProfile.vue')
	// },
	{
		path: '/internet_thing_overview',
		name: 'internet_thing_overview',
		meta: {
			title: '物联网概况',
		},
		component: () => import('@/views/ScreenFile/farmland_foundation_management/child_page/ChildLeftTwo/InternetOfThingsOverview.vue')
	},
	{
		path: '/output_profile',
		name: 'output_profile',
		meta: {
			title: '产量概况',
		},
		component: () => import('@/views/ScreenFile/farmland_foundation_management/child_page/ChildLeftThree/OutputProfile.vue')
	},
	{
		path: "/traceability-system",
		name: "traceability-system",
		meta: { tile: "粮食产品溯源系统" },
		children: [...tracea_system],
		component: () => import("@/views/BackSysFile/traceability_system/home/TraceaBilityHome.vue")
	},
	{
		path: "/farmland_basic_information_data_platform",
		name: "farmland_basic_information_data_platform",
		meta: { tile: "农田基础信息数据平台" },
		children: [...farmland_basic_management],
		component: () => import("@/views/BackSysFile/farmland_basic_information_data_platform/home/FarmlandHome.vue")
	},
	{
		path: "/industry_fusion_sys",
		name: "industry_fusion_sys",
		meta: { tile: "产业融合系统" },
		children: [...industry_fusion_sys],
		component: () => import("@/views/BackSysFile/industry_fusion_sys/home/IndustryFusionHome.vue")
	},
	// {
	// 	path: '/404',
	// 	name: '404',
	// 	meta: {
	// 		title: '404',
	// 	},
	// 	component: () => import('@/views/404/404.vue')
	// },
	// {
	// 	path: '/403',
	// 	name: '403',
	// 	meta: {
	// 		title: '403',
	// 	},
	// 	component: () => import('@/views/403/403.vue')
	// },
	// {
	// 	path: '/500',
	// 	name: '500',
	// 	meta: {
	// 		title: '500',
	// 	},
	// 	component: () => import('@/views/500/500.vue')
	// },
	// {
	// 	path: '*',
	// 	redirect: '/404'
	// }
]

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

export default router
