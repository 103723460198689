import { system_management } from '@/router/back_stage_management/system_management/index'
import { base_management } from '@/router/back_stage_management/base_management/index'
import { pest_management } from './Pest bank'
import { alarm_management } from './alarm_management'
import { device_management } from './device_management'
import { water_and_fertilizer_management } from './water_and_fertilizer_management'


export const back_stage_management = [
    {
        path: '/back-stage-management-welecome',
        name: 'back-stage-management-welecome',
        meta: {
            title: '欢迎',
        },
        component: () => import('@/views/BackSysFile/back_stage_management/BackWelecome.vue')
    },
    ...system_management,
    ...base_management,
    ...pest_management,
    ...alarm_management,
    ...device_management,
    ...water_and_fertilizer_management
] 