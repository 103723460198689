export const warehouse_management = [
    {
        path: "/warehouse_record",
        name: "warehouse_record",
        meta: { title: "仓储记录" },
        component: () => import("@/views/BackSysFile/traceability_system/warehouse_management/Warehouse_record/WarehouseRecord.vue"),
    }, {
        path: "/warehouse_inventory",
        name: "warehouse_inventory",
        meta: { title: "仓储库存" },
        component: () => import("@/views/BackSysFile/traceability_system/warehouse_management/Warehouse_inventory/WarehouseInventory.vue"),
    }
]