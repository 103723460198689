export const industry_fusion_sys = [
    // 销售土特产
    {
        path: "/sell_local_specialty",
        name: "sell_local_specialty",
        meta: { title: "销售土特产" },
        component: () => import("@/views/BackSysFile/industry_fusion_sys/sell_local_specialty/sell_local_specialty/SellLocalSpecialty.vue"),
    },

    // 旅游研学客流量 tourist number
    {
        path: "/tourist_tourist",
        name: "tourist_tourist",
        meta: { title: "旅游研学客流量" },
        component: () => import("@/views/BackSysFile/industry_fusion_sys/tourist_tourist/tourist_tourist/Tourist_Tourist.vue"),
    },


    {
        path: "/base_info_data_statistics",
        name: "base_info_data_statistics",
        meta: { title: "种(养)植基础信息" },
        component: () => import("@/views/BackSysFile/industry_fusion_sys/data_statistics/base_info_data_statistics/BaseInfoDataStatistics.vue"),
    },
    {
        path: "/breed_data_statistics",
        name: "breed_data_statistics",
        meta: { title: "种(养)植投入记录" },
        component: () => import("@/views/BackSysFile/industry_fusion_sys/data_statistics/breed_data_statistics/BreedDataStatistics.vue"),
    },
    {
        path: "/output_data_statistics",
        name: "output_data_statistics",
        meta: { title: "种(养)植产出记录" },
        component: () => import("@/views/BackSysFile/industry_fusion_sys/data_statistics/output_data_statistics/OutputDataStatistics.vue"),
    }

]