export const crop_variety = [
    {
        path: "/variety",
        name: "variety",
        meta: { title: "品种管理" },
        component: () => import("@/views/BackSysFile/traceability_system/crop_variety/variety/Variety.vue"),
    }, {
        path: "/seed_info",
        name: "seed_info",
        meta: { title: "种子扩展信息" },
        component: () => import("@/views/BackSysFile/traceability_system/crop_variety/seed_info/SeedInfo.vue"),
    }
]