import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);
export default new Vuex.Store({
    // 用于持久化存储
    plugins: [createPersistedState({
        key: 'open_plant',
        paths: ['menuList', "currentMenus", "id", "name", "breadcrumb"],
        storage: window.sessionStorage // 将状态存储在会话存储中
    })],
    state: {
        // 菜单列表
        menuList: [],
        currentMenus: [],
        id: "",
        name: "",
        mapMsg: "",
        polygon: "",
        polygonEditor: "",
        areaAndPathData: "",
        location: null,
        breadcrumb: null
    },
    mutations: {
        //设置菜单
        SET_CURRENT_MENU(state, data) {
            state.currentMenus = data;
        },
        SET_MENU_LIST(state, data) {
            state.menuList = data;
        },
        SET_USER_ID(state, data) {
            state.id = data
        },
        SET_USER_Name(state, data) {
            state.name = data
        },
        SET_MAP_MSG(state, data) {
            state.mapMsg = data
        },
        SET_POLYGON(state, data) {
            state.polygon = data
        },
        SET_POLYGON_EDITOR(state, data) {
            console.log(data)
            state.polygonEditor = data
        },
        SET_AREA_AND_PATH_DATA(state, data) {
            state.areaAndPathData = data
        },
        SET_LOCATION(state, data) {
            state.location = data
        },
        SET_BREADCRUMB(state, data) {
            state.breadcrumb = data
        }

    },
    actions: {
        //使用 async
        async setCurrentMenu({ commit }, data) {
            commit('SET_CURRENT_MENU', data);
        },
        async setMenuList({ commit }, data) {
            commit('SET_MENU_LIST', data);
        },
        async setUserId({ commit }, data) {
            commit('SET_USER_ID', data)
        },
        async setUserName({ commit }, data) {
            commit('SET_USER_Name', data)
        },
        async setMapMsg({ commit }, data) {
            commit('SET_MAP_MSG', data)
        },
        async setPolygon({ commit }, data) {
            commit('SET_POLYGON', data)
        },
        async setPolygonEditor({ commit }, data) {
            commit('SET_POLYGON_EDITOR', data)
        },
        async setAreaAndPathData({ commit }, data) {
            commit('SET_AREA_AND_PATH_DATA', data)
        },
        async setLocation({ commit }, data) {
            commit('SET_LOCATION', data)
        },
        async setBreadcrumb({ commit }, data) {
            commit('SET_BREADCRUMB', data)
        }

    },
    modules: {
        // ...
    },
});